





































































@import '@design';

.bluegiant-contact-form {
  .v-text-field .v-input__control .v-input__slot {
    &::before,
    &::after {
      border-color: $color-bluegiant-red;
      transform: scaleX(1);
    }
  }
}
